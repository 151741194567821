export const permissions = {
    AccountCard: {
        translate: 'کارت حساب',
        create: 'AccountCard create',
        read: 'AccountCard read',
        update: 'AccountCard update',
        delete: 'AccountCard delete',
    },
    Address: {
        translate: 'آدرس',
        create: 'Address create',
        read: 'Address read',
        update: 'Address update',
        delete: 'Address delete',
    },
    Admin: {
        translate: 'مدیر',
        create: 'Admin create',
        read: 'Admin read',
        update: 'Admin update',
        delete: 'Admin delete',
    },
    Advance: {
        translate: 'پیشرفته',
        create: 'Advance create',
        read: 'Advance read',
        update: 'Advance update',
        delete: 'Advance delete',
    },
    Bank: {
        translate: 'بانک',
        create: 'Bank create',
        read: 'Bank read',
        update: 'Bank update',
        delete: 'Bank delete',
    },
    Bid: {
        translate: 'پیشنهاد',
        create: 'Bid create',
        read: 'Bid read',
        update: 'Bid update',
        delete: 'Bid delete',
    },
    Blog: {
        translate: 'بلاگ',
        create: 'Blog create',
        read: 'Blog read',
        update: 'Blog update',
        delete: 'Blog delete',
    },
    BlogCategory: {
        translate: 'دسته‌بندی بلاگ',
        create: 'BlogCategory create',
        read: 'BlogCategory read',
        update: 'BlogCategory update',
        delete: 'BlogCategory delete',
    },
    BlogToCategory: {
        translate: 'ارتباط بلاگ و دسته‌بندی',
        create: 'BlogToCategory create',
        read: 'BlogToCategory read',
        update: 'BlogToCategory update',
        delete: 'BlogToCategory delete',
    },
    Card: {
        translate: 'کارت',
        create: 'Card create',
        read: 'Card read',
        update: 'Card update',
        delete: 'Card delete',
    },
    Change: {
        translate: 'تغییر',
        create: 'Change create',
        read: 'Change read',
        update: 'Change update',
        delete: 'Change delete',
    },
    ChangeBaseOnCurrency: {
        translate: 'تغییر بر اساس ارز',
        create: 'ChangeBaseOnCurrency create',
        read: 'ChangeBaseOnCurrency read',
        update: 'ChangeBaseOnCurrency update',
        delete: 'ChangeBaseOnCurrency delete',
    },
    Client: {
        translate: 'مشتری',
        create: 'Client create',
        read: 'Client read',
        update: 'Client update',
        delete: 'Client delete',
    },
    ClientCoin: {
        translate: 'ارز مشتری',
        create: 'ClientCoin create',
        read: 'ClientCoin read',
        update: 'ClientCoin update',
        delete: 'ClientCoin delete',
    },
    ClientNotification: {
        translate: 'اعلان مشتری',
        create: 'ClientNotification create',
        read: 'ClientNotification read',
        update: 'ClientNotification update',
        delete: 'ClientNotification delete',
    },
    ClientToTrader: {
        translate: 'مشتری به معامله‌گر',
        create: 'ClientToTrader create',
        read: 'ClientToTrader read',
        update: 'ClientToTrader update',
        delete: 'ClientToTrader delete',
    },
    Coin: {
        translate: 'ارز دیجیتال',
        create: 'Coin create',
        read: 'Coin read',
        update: 'Coin update',
        delete: 'Coin delete',
    },
    CoinChange: {
        translate: 'تغییر ارز دیجیتال',
        create: 'CoinChange create',
        read: 'CoinChange read',
        update: 'CoinChange update',
        delete: 'CoinChange delete',
    },
    CoinNetwork: {
        translate: 'شبکه ارز دیجیتال',
        create: 'CoinNetwork create',
        read: 'CoinNetwork read',
        update: 'CoinNetwork update',
        delete: 'CoinNetwork delete',
    },
    Contactus: {
        translate: 'تماس با ما',
        create: 'Contactus create',
        read: 'Contactus read',
        update: 'Contactus update',
        delete: 'Contactus delete',
    },
    Country: {
        translate: 'کشور',
        create: 'Country create',
        read: 'Country read',
        update: 'Country update',
        delete: 'Country delete',
    },
    Currency: {
        translate: 'ارز',
        create: 'Currency create',
        read: 'Currency read',
        update: 'Currency update',
        delete: 'Currency delete',
    },
    Deposit: {
        translate: 'واریز',
        create: 'Deposit create',
        read: 'Deposit read',
        update: 'Deposit update',
        delete: 'Deposit delete',
    },
    DepositCach: {
        translate: 'واریز نقدی',
        create: 'DepositCach create',
        read: 'DepositCach read',
        update: 'DepositCach update',
        delete: 'DepositCach delete',
    },
    Faq: {
        translate: 'سؤالات متداول',
        create: 'Faq create',
        read: 'Faq read',
        update: 'Faq update',
        delete: 'Faq delete',
    },
    FavoriteCoin: {
        translate: 'ارز دیجیتال مورد علاقه',
        create: 'FavoriteCoin create',
        read: 'FavoriteCoin read',
        update: 'FavoriteCoin update',
        delete: 'FavoriteCoin delete',
    },
    FollowTrader: {
        translate: 'دنبال کردن معامله‌گر',
        create: 'FollowTrader create',
        read: 'FollowTrader read',
        update: 'FollowTrader update',
        delete: 'FollowTrader delete',
    },
    Intermediate: {
        translate: 'میانی',
        create: 'Intermediate create',
        read: 'Intermediate read',
        update: 'Intermediate update',
        delete: 'Intermediate delete',
    },
    LevelTwo: {
        translate: 'سطح دو',
        create: 'LevelTwo create',
        read: 'LevelTwo read',
        update: 'LevelTwo update',
        delete: 'LevelTwo delete',
    },
    Network: {
        translate: 'شبکه',
        create: 'Network create',
        read: 'Network read',
        update: 'Network update',
        delete: 'Network delete',
    },
    Offer: {
        translate: 'پیشنهاد',
        create: 'Offer create',
        read: 'Offer read',
        update: 'Offer update',
        delete: 'Offer delete',
    },
    Page: {
        translate: 'صفحه',
        create: 'Page create',
        read: 'Page read',
        update: 'Page update',
        delete: 'Page delete',
    },
    Pair: {
        translate: 'جفت ارز',
        create: 'Pair create',
        read: 'Pair read',
        update: 'Pair update',
        delete: 'Pair delete',
    },
    PairBaseOnCurrency: {
        translate: 'جفت ارز بر اساس ارز',
        create: 'PairBaseOnCurrency create',
        read: 'PairBaseOnCurrency read',
        update: 'PairBaseOnCurrency update',
        delete: 'PairBaseOnCurrency delete',
    },
    Permission: {
        translate: 'دسترسی',
        create: 'Permission create',
        read: 'Permission read',
        update: 'Permission update',
        delete: 'Permission delete',
    },
    PermissionRole: {
        translate: 'نقش دسترسی',
        create: 'PermissionRole create',
        read: 'PermissionRole read',
        update: 'PermissionRole update',
        delete: 'PermissionRole delete',
    },
    Risk: {
        translate: 'ریسک',
        create: 'Risk create',
        read: 'Risk read',
        update: 'Risk update',
        delete: 'Risk delete',
    },
    Role: {
        translate: 'نقش',
        create: 'Role create',
        read: 'Role read',
        update: 'Role update',
        delete: 'Role delete',
    },
    Service: {
        translate: 'سرویس',
        create: 'Service create',
        read: 'Service read',
        update: 'Service update',
        delete: 'Service delete',
    },
    SwapHistory: {
        translate: 'تاریخچه تبادل',
        create: 'SwapHistory create',
        read: 'SwapHistory read',
        update: 'SwapHistory update',
        delete: 'SwapHistory delete',
    },
    Text: {
        translate: 'متن',
        create: 'Text create',
        read: 'Text read',
        update: 'Text update',
        delete: 'Text delete',
    },
    Transaction: {
        translate: 'معامله‌',
        create: 'Transaction create',
        read: 'Transaction read',
        update: 'Transaction update',
        delete: 'Transaction delete',
    },
    User: {
        translate: 'کاربر',
        create: 'User create',
        read: 'User read',
        update: 'User update',
        delete: 'User delete',
    },
    Withdraw: {
        translate: 'برداشت',
        create: 'Withdraw create',
        read: 'Withdraw read',
        update: 'Withdraw update',
        delete: 'Withdraw delete',
    },
    WithdrawCache: {
        translate: 'برداشت نقدی',
        create: 'WithdrawCache create',
        read: 'WithdrawCache read',
        update: 'WithdrawCache update',
        delete: 'WithdrawCache delete',
    },
    Token: {
        translate: 'نشر توکن',
        create: 'Token create',
        read: 'Token read',
        update: 'Token update',
        delete: 'Token delete',
    },
};
